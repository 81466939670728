import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from 'prismic'
import { prismicRepo } from '../../prismic-configuration'

import AboutPage from 'views/about/AboutPage'

export const query = graphql`
  query AboutPageQuery {
    prismicAbout(uid: { eq: "about" }, lang: { eq: "en-us" }) {
      _previewable
      uid
      data {
        page_meta_title
        page_meta_description
        page_meta_thumbnail {
          url
          dimensions {
            height
            width
          }
          alt
        }
        hero_title {
          text
        }
        hero_text {
          text
        }
        hero_image {
          alt
          url
          thumbnails {
            small {
              url
              dimensions {
                width
                height
              }
            }
          }
        }
        hero_image_medium {
          alt
          url
          thumbnails {
            small {
              url
              dimensions {
                width
                height
              }
            }
          }
        }
        hero_image_small {
          alt
          url
          thumbnails {
            small {
              url
              dimensions {
                width
                height
              }
            }
          }
        }
        what_title {
          text
        }
        what_text {
          text
        }
        friends_heading {
          text
        }
        friends_text {
          text
        }
        friends_showcase {
          title: friend_title
          image: friend_image {
            alt
            url
            dimensions {
              width
              height
            }
          }
        }
        body {
          ...AboutBodyWhatSectionSliceFragment
        }
        # TEAM RELATED DATA
        team_title {
          text
        }
        team_text {
          text
        }
        team_link {
          text
          richText
        }
        show_team_cta
        team_cta_title
        team_cta_description
        team_cta_link {
          url
        }
        team_members {
          ...PrismicAboutDataTeamMembersFragment
        }
      }
    }
  }
  fragment PrismicAboutDataTeamMembersFragment on PrismicAboutDataTeamMembers {
    member_name
    member_position
    member_text {
      text
    }
    member_image {
      dimensions {
        width
        height
      }
      url
      alt
      thumbnails {
        small {
          dimensions {
            width
            height
          }
          gatsbyImageData
          url
        }
      }
    }
  }
`

export default withPrismicPreview(AboutPage, [
  {
    repositoryName: prismicRepo,
    linkResolver,
  },
])
