import React from 'react'
import classNames from 'classnames/bind'

import { Container, Section, Grid } from 'components/ui/layout'
import SectionText from 'components/ui/section-text'
import TeamImage from '../team-image'
import TeamCTA from '../team-image/team-cta'

import * as s from './Team.module.css'
const cn = classNames.bind(s)

const Team = ({ data }) => {
  const page = data?.prismicAbout?.data
  if (!page) return null

  const { width: w, height: h } = page.team_members.find(el => el?.member_image?.dimensions).member_image.dimensions
  const r = w / h

  return (
    <div className={cn('wrapper')}>
      <Container className={cn('introContainer')}>
        <div className={cn('introContent')}>
          <Section className={cn('spacing')}>
            <SectionText title={page.team_title} text={page.team_text} link={page.team_link} />
          </Section>
        </div>
      </Container>
      <Container>
        <Grid className={cn('members')}>
          {page.team_members.map((member, index) => (
            <div key={member.member_name} className={cn('member')}>
              <TeamImage index={index} aspect={r} member={member} />
            </div>
          ))}
          {page.show_team_cta && (
            <div className={cn('member')}>
              <TeamCTA ratio={r} page={page} />
            </div>
          )}
        </Grid>
      </Container>
    </div>
  )
}

export default Team
