// extracted by mini-css-extract-plugin
export var teamImage = "TeamImage-module--teamImage--O3Avf";
export var canvasBackground = "TeamImage-module--canvasBackground--6mMew";
export var domBackground = "TeamImage-module--domBackground--3RbNs";
export var content = "TeamImage-module--content--WtHOt";
export var name = "TeamImage-module--name--23unt";
export var position = "TeamImage-module--position--fKxBi";
export var text = "TeamImage-module--text--U1ybF";
export var cta = "TeamImage-module--cta--cquSH";
export var inner = "TeamImage-module--inner--6K3Aj";
export var description = "TeamImage-module--description--2kADM";
export var title = "TeamImage-module--title--BO14Q";