import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import * as s from '../TeamImage.module.css'

import Clickable from 'components/ui/clickable'

const cn = classNames.bind(s)

const TeamCta = ({ ratio, page: { team_cta_title, team_cta_description, team_cta_link } }) => {
  return (
    <Clickable className={cn('cta', 'teamImage')} to={team_cta_link.url} style={{ '--ratio': ratio }}>
      <div className={cn('inner')}>
        <h4 className={cn('description')}>{team_cta_description}</h4>
        <h3 className={cn('title')}>{team_cta_title}</h3>
      </div>
    </Clickable>
  )
}

TeamCta.propTypes = {
  page: PropTypes.any,
  team_cta_title: PropTypes.string,
  team_cta_description: PropTypes.string,
  team_cta_link: PropTypes.string,
  ratio: PropTypes.number,
}

export default TeamCta
