import React from 'react'
import classNames from 'classnames/bind'

import { Container, Section } from 'components/ui/layout'
import SectionText from 'components/ui/section-text'

import * as s from './FriendsAndPartners.module.css'
const cn = classNames.bind(s)

const FriendsAndPartners = ({ data }) => {
  const page = data?.prismicAbout?.data
  if (!page) return null

  return (
    <div className={cn('wrapper')}>
      <Container className={cn('introContainer')}>
        <div className={cn('introContent')}>
          <Section className={cn('spacing')}>
            <SectionText title={page.friends_heading} text={page.friends_text} />
          </Section>
        </div>
      </Container>
    </div>
  )
}

export default FriendsAndPartners
