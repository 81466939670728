import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import { useCanvas, ScrollScene } from '@14islands/r3f-scroll-rig'

import ResponsiveImage from 'components/ui/responsive-image'
import LettersAnimation from 'components/motion/letters-animation/LettersAnimation'

import AboutHeroImageMesh from './about-hero-image-mesh'

import config from 'config'

import * as s from './AboutHero.module.css'
const cn = classNames.bind(s)

const AboutHero = ({ children, title, text, imageLarge, imageMedium, imageSmall }) => {
  const blobImg1 = useRef()
  const blobImg2 = useRef()
  const blobImg3 = useRef()
  const blobWrapper1 = useRef()
  const blobWrapper2 = useRef()
  const blobWrapper3 = useRef()

  // tell GlobalCanvas to render our WebGl Image
  useCanvas(({ hasVirtualScrollbar }) => {
    if (!hasVirtualScrollbar) return null
    return (
      <>
        <ScrollScene el={blobWrapper1} lerp={config.scrollLerp} lerpOffset={1} margin={100}>
          {props => <AboutHeroImageMesh image={blobImg1} {...props} index={0} />}
        </ScrollScene>
        <ScrollScene el={blobWrapper2} lerp={config.scrollLerp} lerpOffset={0.8} margin={100}>
          {props => <AboutHeroImageMesh image={blobImg2} {...props} index={1} />}
        </ScrollScene>
        <ScrollScene el={blobWrapper3} lerp={config.scrollLerp} lerpOffset={0.9} margin={100}>
          {props => <AboutHeroImageMesh image={blobImg3} {...props} index={2} />}
        </ScrollScene>
        ,
      </>
    )
  })

  return (
    <div className={cn('hero')}>
      <div className={cn('title')}>
        <div className={cn('wrapper')}>
          <LettersAnimation text={title.text} stagger={0.025} className={cn('title')} />
        </div>
      </div>

      <div className={cn('blobArea')}>
        <div className={cn('blob', 'blob1')} ref={blobWrapper1}>
          <ResponsiveImage ref={blobImg1} image={imageSmall} className={cn('image')} />
        </div>

        <div className={cn('blob', 'blob2')} ref={blobWrapper2}>
          <ResponsiveImage ref={blobImg2} image={imageMedium} className={cn('image')} />
        </div>

        <div className={cn('blob', 'blob3')} ref={blobWrapper3}>
          <ResponsiveImage ref={blobImg3} image={imageLarge} className={cn('image')} />
        </div>
      </div>
    </div>
  )
}

AboutHero.propTypes = {
  title: PropTypes.any,
  text: PropTypes.any,
  image: PropTypes.object,
  imageLarge: PropTypes.shape({ url: PropTypes.string, dimensions: PropTypes.object }),
  imageMedium: PropTypes.shape({ url: PropTypes.string, dimensions: PropTypes.object }),
  imageSmall: PropTypes.shape({ url: PropTypes.string, dimensions: PropTypes.object }),
}

export default AboutHero
