import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import Layout from 'layouts'
import SEO from 'components/seo'
import AboutHero from './components/about-hero'
import WhatWeDo from './components/what-we-do'
import FriendsAndPartners from './components/friends-and-partners'
import Logos from './components/logos'
import Team from './components/team'

import * as s from './AboutPage.module.css'

const cn = classNames.bind(s)

const AboutPage = ({ location, data }) => {
  const page = data?.prismicAbout?.data
  if (!page) return null

  const metaTitle = page.page_meta_title
  const metaDescription = page.page_meta_description
  const metaThumbnail = page.page_meta_thumbnail

  return (
    <Layout className={cn('aboutPage')} background="#f5f5f2" labCTA={false} fadeIn={false}>
      <SEO title={metaTitle} description={metaDescription} thumbnail={metaThumbnail} />

      <AboutHero
        imageSmall={page.hero_image_small}
        imageMedium={page.hero_image_medium}
        imageLarge={page.hero_image}
        title={page.hero_title}
        text={page.hero_text}
      />

      <WhatWeDo data={data} />
      <FriendsAndPartners data={data} />
      <Logos data={data} />
      <Team data={data} />
    </Layout>
  )
}

AboutPage.propTypes = {
  location: PropTypes.object,
}

export default AboutPage
