import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFrame, useThree } from '@react-three/fiber'
import classNames from 'classnames/bind'
import { useSpring } from 'framer-motion'
import lerp from '@14islands/lerp'

import useUIContext from 'context/ui'

import ResponsiveImage from 'components/ui/responsive-image'
import { useCanvas, ScrollScene, useScrollRig, useImgTagAsTexture } from '@14islands/r3f-scroll-rig'

import './TeamImageMaterial'

import config from 'config'

import * as s from './TeamImage.module.css'
const cn = classNames.bind(s)

const SPEED = 0.5 // idle blob speed

let timer

const TeamImageMesh = ({ el, scene, index, image, scale, state: { bounds }, inViewport, layers }) => {
  const { invalidate, size, camera } = useThree()
  const { preloadScene } = useScrollRig()
  const pixelRatio = useThree(s => s.viewport.dpr)

  const material = useRef()
  const mouse = useRef({ x: 0, y: 0, vx: 0, vy: 0, hoverX: 0, hoverY: 0 }).current
  const local = useRef({ blob: 0 }).current

  const isNativeCursorHidden = useUIContext(state => state.isNativeCursorHidden)

  const [texture, disposeBitmap] = useImgTagAsTexture(image.current)

  const edgeEffect = useSpring(0, { stiffness: 250, damping: 15, restDelta: 0.001, restSpeed: 0.001 })

  const isHovering = isNativeCursorHidden?.el === el

  useEffect(() => edgeEffect.onChange(invalidate), [])

  const setMousePos = e => {
    // Mouse screen position in px units
    mouse.y = -(e.clientY / size.height) * 2 + 1
    mouse.x = (e.clientX / size.width) * 2 - 1

    // Mouse screen position in viewport units
    mouse.vy = mouse.y * size.height * 0.5
    mouse.vx = mouse.x * size.width * 0.5
  }

  const onMouseMove = e => {
    setMousePos(e)
  }

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove)
    return () => window.removeEventListener('mousemove', onMouseMove)
  }, [size])

  // dimensions
  useEffect(() => {
    const { width, height } = bounds
    material.current.planeSize = { width, height }
    material.current.resolution = { width: size.width, height: size.height }
  }, [size])

  useEffect(() => {
    if (!texture) return
    material.current.map = texture
    setTimeout(() => {
      if (!scene || !camera) return
      preloadScene(scene, camera, null, disposeBitmap)
    }, index * 17)
  }, [texture])

  useEffect(() => {
    // store pos where hover happened
    mouse.hoverX = mouse.vx
    mouse.hoverY = mouse.vy

    if (isHovering) {
      if (Math.abs(edgeEffect.get()) < 0.9) {
        // store pos where hover happened
        setMousePos(isNativeCursorHidden?.event)
        mouse.hoverX = mouse.vx
        mouse.hoverY = mouse.vy

        edgeEffect.set(1, false)
        edgeEffect.set(0)
      }
    } else {
      if (Math.abs(edgeEffect.get()) < 0.2) {
        mouse.hoverX = mouse.vx
        mouse.hoverY = mouse.vy

        edgeEffect.set(0.5, false)
        edgeEffect.set(0)
      }
    }

    invalidate()
  }, [isHovering])

  useEffect(() => {
    local.blob = isHovering ? 1.0 : 0
    invalidate()
  }, [isHovering])

  useFrame((_, delta) => {
    if (!bounds.inViewport || !material.current) return

    material.current.time += delta * SPEED

    material.current.isHovering = isHovering
    material.current.edgeEffect = edgeEffect.get()

    const hasBlob = local.blob === 1
    material.current.blobEffect = lerp(material.current.blobEffect, local.blob, hasBlob ? 0.14 : 0.3, delta)

    material.current.mouse.x = lerp(material.current.mouse.x, mouse.x, 0.14, delta)
    material.current.mouse.y = lerp(material.current.mouse.y, mouse.y, 0.14, delta)

    material.current.vmouse.x = mouse.hoverX
    material.current.vmouse.y = mouse.hoverY

    if (hasBlob) {
      invalidate()
    }
  })

  return (
    <mesh>
      <planeBufferGeometry attach="geometry" args={[scale.width, scale.height, 128, 128]} />
      <teamImageMaterial attach="material" ref={material} transparent pixelRatio={pixelRatio} />
    </mesh>
  )
}

TeamImageMesh.propTypes = {
  image: PropTypes.any,
  ...ScrollScene.childPropTypes,
}

const TeamImage = props => {
  const {
    member: { member_name, member_position, member_text, member_image },
    aspect,
    index,
  } = props

  const el = useRef()
  const imgEl = useRef()
  const content = useRef()
  const toggleNativeCursor = useUIContext(state => state.toggleNativeCursor)

  const prismicImage = member_image

  // tell GlobalCanvas to render our WebGl Image
  useCanvas(({ hasVirtualScrollbar }) => {
    if (!hasVirtualScrollbar) return null
    return (
      <ScrollScene el={el} lerp={config.scrollLerp}>
        {props => <TeamImageMesh index={index} image={imgEl} {...props} />}
      </ScrollScene>
    )
  })

  return (
    <div
      className={cn('teamImage')}
      onMouseEnter={event => {
        event.persist()
        clearTimeout(timer)
        timer = setTimeout(() => {
          toggleNativeCursor({ el, event })
        }, 17)
      }}
      onMouseLeave={() => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          toggleNativeCursor(false)
        }, 17)
      }}
      style={{
        '--aspect-ratio': aspect,
      }}
    >
      <ResponsiveImage ref={imgEl} image={prismicImage} />

      <div className={cn('canvasBackground')} ref={el} />
      <div className={cn('domBackground')} />
      <div className={cn('content')} ref={content}>
        <h3 className={cn('name')}>{member_name}</h3>
        <p className={cn('position')}>{member_position}</p>
        <div className={cn('text')}>{member_text.text}</div>
      </div>
    </div>
  )
}

TeamImage.propTypes = {
  member: PropTypes.any,
  member_name: PropTypes.string,
  aspect: PropTypes.number,
  index: PropTypes.number,
}

export default TeamImage
