import React, { useRef, useEffect, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { useSpring, useViewportScroll } from 'framer-motion'
import PropTypes from 'prop-types'
import lerp from '@14islands/lerp'

import useUIContext from 'context/ui'

import requestIdleCallback from 'lib/requestIdleCallback'
import { useScrollRig, ScrollScene, useImgTagAsTexture } from '@14islands/r3f-scroll-rig'

import './AboutHeroImageMaterial'

const SPEED = 0.1 // idle blob speed

const AboutHeroImageMesh = ({ image, el, scene, scale, state: { bounds }, lerpOffset, index }) => {
  const mesh = useRef()
  const material = useRef()
  const local = useRef({ velocity: 0, opacity: 0, start: Date.now() }).current
  const [isHovering, setIsHovering] = useState(false)

  const { scrollY } = useViewportScroll()
  const { invalidate, size, viewport, camera } = useThree()
  const { preloadScene } = useScrollRig()
  const pixelRatio = useThree(s => s.viewport.dpr)

  const isPageTransitionActive = useUIContext(state => state.isPageTransitionActive)

  const [texture, disposeBitmap] = useImgTagAsTexture(image.current)

  const blobPosX = useSpring(0, { stiffness: 2000, damping: 100, restDelta: 0.01, restSpeed: 0.01 })
  const blobPosY = useSpring(0, { stiffness: 2000, damping: 100, restDelta: 0.01, restSpeed: 0.01 })
  const imgEffect = useSpring(0, { stiffness: 30, damping: 50, restDelta: 0.001, restSpeed: 0.001 })
  const maskSize = useSpring(0, {
    stiffness: 30,
    damping: 30,
    restDelta: 0.001,
    restSpeed: 0.001,
  })

  const mouse = useRef({ x: 0, y: 0, vx: 0, vy: 0, showBlob: false }).current
  const mouseLocal = useRef({ x: 0, y: 0 }).current

  const onMouseMove = e => {
    if (isPageTransitionActive) return

    // Mouse screen position in px units
    mouse.y = -(e.clientY / size.height) * 2 + 1
    mouse.x = (e.clientX / size.width) * 2 - 1

    // TODO SHARE blob X/Y between all images instead of multiple springs and mouse listeners
    // TODO replace with lerp instead of spring?
    blobPosX.set(mouse.x)
    blobPosY.set(mouse.y)

    // Mouse screen position in viewport units
    mouse.vy = mouse.y * viewport.height * 0.5
    mouse.vx = mouse.x * viewport.width * 0.5

    // Mouse relative position over element ([-1, 1])
    mouseLocal.y = -((e.clientY - bounds.top + scrollY.get()) / bounds.height) * 2 + 1
    mouseLocal.x = ((e.clientX - bounds.left) / bounds.width) * 2 - 1
  }

  const onMouseEnter = () => {
    requestIdleCallback(() => setIsHovering(true))
  }
  const onMouseLeave = () => {
    requestIdleCallback(() => setIsHovering(false))
  }

  useEffect(() => {
    const domEl = el.current
    domEl.addEventListener('mouseenter', onMouseEnter)
    domEl.addEventListener('mouseleave', onMouseLeave)
    window.addEventListener('mousemove', onMouseMove)
    return () => {
      domEl.addEventListener('mouseenter', onMouseEnter)
      domEl.addEventListener('mouseleave', onMouseLeave)
      window.removeEventListener('mousemove', onMouseMove)
    }
  }, [viewport, isPageTransitionActive])

  // invalidate on spring changes
  useEffect(() => blobPosX.onChange(() => isHovering && invalidate()), [])
  useEffect(() => blobPosY.onChange(() => isHovering && invalidate()), [])
  useEffect(() => imgEffect.onChange(invalidate), [])
  useEffect(() => maskSize.onChange(invalidate), [])

  // Set loaded image as texture ap
  useEffect(() => {
    if (!texture) return
    // Upload texture to GPU so it's resized ahead of time
    const timer = setTimeout(() => {
      if (!scene || !camera) return
      material.current.map = texture
      preloadScene(scene, camera, null, disposeBitmap)
    }, index)

    return () => clearTimeout(timer)
  }, [texture])

  // dimensions
  useEffect(() => {
    material.current.planeSize = { width: bounds.width, height: bounds.height }
    material.current.resolution = { width: size.width, height: size.height }
  }, [size, bounds.width, bounds.height])

  // HOVER effect
  useEffect(() => {
    let velocity = Math.abs(blobPosX.getVelocity()) + Math.abs(blobPosY.getVelocity())
    const mouseVelocity = Math.min(1.0, velocity * 0.5) || 0

    if (Math.abs(imgEffect.get()) < 2) {
      imgEffect.set(isHovering ? mouseVelocity : -mouseVelocity, false)
      imgEffect.set(0)
    }
    invalidate()
  }, [isHovering])

  // Render loop
  useFrame((_, delta) => {
    if (!material.current) return
    if (!bounds.inViewport) {
      material.current.time = 0
      return
    }

    // show
    // if (!local.opacity && bounds.progress > 0.14 + Math.abs(lerpOffset * 3)) {
    if (material.current.map && !local.opacity && bounds.progress > 0.0 + index * 0.1) {
      maskSize.set(1)
      local.opacity = 1
    }
    const velocity = Math.min(0.01, Math.abs(scrollY.getVelocity()) * 0.00003)
    local.velocity = lerp(local.velocity, velocity, 0.03, delta)
    material.current.time += delta * SPEED + local.velocity

    material.current.imgEffect = imgEffect.get()
    material.current.imgOpacity = lerp(material.current.imgOpacity, local.opacity, 0.05, delta)
    material.current.maskSize = maskSize.get()

    material.current.vmouse.x = lerp(material.current.vmouse.x, mouse.vx, 0.1, delta)
    material.current.vmouse.y = lerp(material.current.vmouse.y, mouse.vy, 0.1, delta)

    invalidate() // always render when mounted
  })

  return (
    <mesh ref={mesh}>
      <planeBufferGeometry attach="geometry" args={[scale.width, scale.height, 128, 128]} />
      <aboutHeroImageMaterial attach="material" ref={material} transparent pixelRatio={pixelRatio} />
    </mesh>
  )
}

AboutHeroImageMesh.propTypes = {
  image: PropTypes.any,
  ...ScrollScene.childPropTypes,
}

export default AboutHeroImageMesh
