import React, { useRef, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic'

import useUIContext from 'context/ui'
import ViewportEnterEffect from 'components/motion/viewport-enter-effect'
import classNames from 'classnames/bind'
import * as s from './SectionText.module.css'

const cn = classNames.bind(s)

const SectionText = ({ title, text, link, underlineLink = 'always', as = 'div', textProps, ...props }, ref) => {
  const linkRef = useRef()
  const setHoveredLink = useUIContext(s => s.setHoveredLink)
  const isScrollRigEnabled = useUIContext(s => s.isScrollRigEnabled)
  const Element = as

  return (
    <section ref={ref} className={cn('sectionText')} {...props}>
      <div className={cn('fakeGrid')}>
        {title?.text && (
          <Element className={cn('title')} {...textProps}>
            {title.text.toUpperCase()}
          </Element>
        )}
        {text?.text && (
          <Element className={cn('text')} {...textProps}>
            {text.text}
          </Element>
        )}
        {link?.text && link?.richText && (
          <ViewportEnterEffect threshold={0.4} disabled={isScrollRigEnabled} effect="drawLineMobile">
            <Element
              className={cn('link', underlineLink === 'always' ? 'underline' : 'underlineOnHover')}
              ref={linkRef}
              onMouseEnter={() => setHoveredLink(linkRef.current)}
              onMouseLeave={() => setHoveredLink(null)}
              text={link.text}
              {...textProps}
            >
              {RichText.render(link.richText)}
            </Element>
          </ViewportEnterEffect>
        )}
      </div>
    </section>
  )
}

SectionText.propTypes = {
  as: PropTypes.any,
  title: PropTypes.any,
  text: PropTypes.any,
  link: PropTypes.any,
  underlineLink: PropTypes.oneOf(['always', 'hover']),
  textProps: PropTypes.any,
}

export default forwardRef(SectionText)
