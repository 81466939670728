import React from 'react'
import classNames from 'classnames/bind'

import { Container, Section } from 'components/ui/layout'
import SectionText from 'components/ui/section-text'
import Slices from 'components/ui/slices'

import * as s from './WhatWeDo.module.css'
const cn = classNames.bind(s)

const WhatWeDo = ({ data }) => {
  const page = data?.prismicAbout?.data
  if (!page) return null

  return (
    <Container className={cn('wrapper')}>
      <Section className={cn('spacing')}>
        <SectionText title={page.what_title} text={page.what_text} />
      </Section>

      <div className={cn('whatMain')}>{page.body?.map(Slices)}</div>
    </Container>
  )
}

export default WhatWeDo
