import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useWindowSize } from '@react-hook/window-size'
import { useInterval } from 'react-use'
import { motion, AnimatePresence } from 'framer-motion'

import { useScrollRig } from '@14islands/r3f-scroll-rig'
import envVars from 'styles/config/env-vars.json'
import { Container } from 'components/ui/layout'
import ViewportEnter from 'components/motion/viewport-enter'

import classNames from 'classnames/bind'
import * as s from './Logos.module.css'
const cn = classNames.bind(s)

const Logos = ({ data }) => {
  const slots = 4
  const delay = 2400

  const { reflow } = useScrollRig()

  const [isVisible, setVisiblity] = useState(false)
  const [viewClass, setViewClass] = useState(null)
  const [aspectRatio, setRatio] = useState(null)
  const [pages, setPages] = useState(null)
  const [activePage, setActivePage] = useState(0)
  const [activeItems, setActiveItems] = useState(null)

  const [viewportWidth] = useWindowSize()
  const breakpoint = parseInt(envVars['environment-variables']['--tablet-breakpoint'])

  const page = data?.prismicAbout?.data
  if (!page) return null

  const items = page?.friends_showcase

  // initially set number of pages based on logos length divided into slots
  // initially get the first image and its aspect ratio
  useEffect(() => {
    const pages = Math.ceil(items.length / slots)
    const r = items[0].image.dimensions.width / items[0].image.dimensions.height

    setPages(pages)
    setRatio(r)
  }, [])

  // resize handler
  useLayoutEffect(() => {
    const vw = viewportWidth < breakpoint ? 'isMobile' : 'isDesktop'
    setViewClass(vw)
    reflow()
  }, [viewportWidth])

  // autoplay interval
  useInterval(() => {
    if (!pages || !isVisible) return
    const page = activePage + 1 < pages ? activePage + 1 : 0
    setActivePage(page)
  }, delay)

  // change page
  useEffect(() => {
    let itemsBuffer = []

    for (let i = 0; i < slots; i++) {
      if (!activeItems) {
        itemsBuffer.push(i)
      } else {
        const delta = activeItems[i] + slots
        const c = delta < items.length ? 0 : items.length
        itemsBuffer.push(delta - c)
      }
    }

    setActiveItems(itemsBuffer)
  }, [activePage])

  // mobile specific render
  const renderMobile = () => {
    if (!activeItems) return
    return (
      <AnimatePresence>
        {activeItems.map((index, i) => {
          const item = page.friends_showcase[index]
          const { title, image } = item

          return (
            <motion.div
              className={cn('logoWrapper')}
              key={title + i}
              initial={{
                opacity: 0,
                y: '20%',
              }}
              animate={{
                opacity: 1,
                y: '0%',
                transition: {
                  delay: i * 0.1,
                },
              }}
              exit={{
                opacity: 0,
                scaleX: 1.1,
                y: '-10%',
                transition: {
                  duration: 0.3,
                },
              }}
            >
              <img aria-hidden={!!i} className={cn('logo')} alt={image.alt} src={image.url} />
            </motion.div>
          )
        })}
      </AnimatePresence>
    )
  }

  // desktop specific render
  const renderDesktop = () => {
    return page.friends_showcase.map(({ title, image }, i) => (
      <div className={cn('logoWrapper')} key={title + i}>
        <img aria-hidden={!!i} className={cn('logo')} alt={image.alt} src={image.url} />
      </div>
    ))
  }

  // check for viewport width
  const conditionalRender = () => {
    if (!viewportWidth) return null // hidrating issue?
    return viewClass === 'isMobile' ? renderMobile() : renderDesktop()
  }

  return (
    <Container>
      <ViewportEnter
        onEnter={() => {
          setVisiblity(true)
        }}
        onExit={() => {
          setVisiblity(false)
        }}
        once={false}
      >
        <div
          className={cn('logos', viewClass)}
          style={{ '--ratio': aspectRatio * (viewClass === 'isMobile' ? 1 : 5 / Math.ceil(items.length / 5)) }}
        >
          {conditionalRender()}
        </div>
      </ViewportEnter>
    </Container>
  )
}

export default Logos
