import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import * as s from './LettersAnimation.module.css'

const cn = classNames.bind(s)

const LettersAnimation = ({ text, stagger = 0.03, className }) => {
  let c = 0
  const delayOffset = 0.3
  const words = useMemo(() => text.split(' '), [text])

  return (
    <h1 className={cn(className)}>
      {words.map((word, i) => (
        <span className={cn('word')} key={word + i}>
          {word.split('').map((char, i) => {
            c++
            return (
              <span
                className={cn('char')}
                aria-hidden="true"
                key={char + i}
                style={{
                  animationDelay: stagger * c + delayOffset + 's',
                }}
              >
                {char !== ' ' ? char : '\u00A0'}
              </span>
            )
          })}
          {i !== words.length - 1 ? '\u00A0' : ''}
        </span>
      ))}
    </h1>
  )
}

LettersAnimation.propTypes = {
  text: PropTypes.string,
  stagger: PropTypes.number,
  className: PropTypes.string,
}

export default LettersAnimation
